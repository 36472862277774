import { Link} from "react-router-dom"

export default function Recent(props) {
  const {routes} = props

  const recent = routes.sort((a, b) => {
    if (a.publishedAt > b.publishedAt) {return -1}
    if (a.publishedAt < b.publishedAt) {return 1}
    return 0
  }).slice(0, 5)

  return (
    <span  id="sidebar-list">
      <h3>Recent</h3>
      <ul>
      {recent.map(item => {
        return (
          <li>
            <Link to={item.url}>{item.headline}</Link>
          </li>
        )
      })}
      </ul>
    </span>
  )
}
