import React from 'react'
import MultipleItemComponent from '../../classes/MultipleItemComponent'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import CallToAction from '../widgets/CallToAction'

class FourLinks extends MultipleItemComponent {

  render() {

    const {item, entries} = this.state
    const {zIndex} = this.props

    if (!entries) return null

    let topEdgeTopStyle = {
      paddingTop: '1rem',
      backgroundColor: '#f3f1e6' // To Do. Make these styles classes.
    }

    switch (item.roughEdgeTop) {
      case 'Off White Hero':
        topEdgeTopStyle = {
          marginTop: -80,
          paddingTop: 40,
          backgroundImage: `url("/images/rough-edge-off-white.png")`,
          backgroundRepeat: "no-repeat",
          zIndex: zIndex
        }
        break
      case 'Lime Green Hero':
        topEdgeTopStyle = {
          marginTop: -80,
          paddingTop: 40,
          backgroundImage: `url("/images/rough-edge-lime-green.png")`,
          backgroundRepeat: "no-repeat",
          zIndex: zIndex
        }
        break
      case 'Off White':
        topEdgeTopStyle = {
          marginTop: -30,
          paddingTop: 35,
          backgroundImage: `url("/images/rough-edge-off-white.png")`,
          backgroundRepeat: "no-repeat",
          zIndex: zIndex
        }
        break
      case 'Lime Green':
        topEdgeTopStyle = {
          marginTop: -30,
          paddingTop: 35,
          backgroundImage: `url("/images/rough-edge-lime-green.png")`,
          backgroundRepeat: "no-repeat",
          zIndex: zIndex
        }
        break
      default:
    }

    return (
      <Container id="four-links" fluid
        style={topEdgeTopStyle}
      >
        <Row className="four-links-content">
          {entries.map((item, i) => {

            let callToAction = item.fields.displayCallToAction ?? false
            let url = false
            if (callToAction && item.fields.callToActionArticle) {
              url = item.fields.callToActionArticle.fields && item.fields.callToActionArticle.fields.url ? item.fields.callToActionArticle.fields.url : false
            }
            if (url && item.fields.callToActionArticle.fields.type === 'Blog Post') {
              url = 'blog/' + url
            }
            if (url && item.fields.callToActionArticle.fields.type === 'Event') {
              url = 'events/' + url
            }

            return (
              <Col xl={3} lg={6} md={6} sm={12} xs={12} key={i}>
                <table style={{verticalAlign: 'bottom'}}>
                  <tbody>
                  <tr>
                    <td width='95%'>
                      <CallToAction
                        internalLink={url}
                        externalLink={item.fields.callToActionUrl}
                      >
                        <h2>{item.fields.headline}</h2>
                      </CallToAction>
                    </td>
                    <td style={{verticalAlign: 'top', textAlign: 'right'}}>
                      <CallToAction
                        internalLink={url}
                        externalLink={item.fields.callToActionUrl}
                      >
                      <span className="fa-stack fa-2x" style={{verticalAlign: 'bottom', marginBottom: '1rem'}}>
                        <i className="fas fa-circle fa-stack-2x"></i>
                        <i className="fas fa-arrow-right fa-stack-1x fa-inverse"></i>
                      </span>
                      </CallToAction>
                    </td>
                  </tr>
                  <tr>
                    <td className="tagline" style={{verticalAlign: 'bottom'}}>
                      <p>{item.fields.tagline}</p>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </Col>
            )
          })}
        </Row>
      </Container>
    )
  }
}

export default FourLinks;
