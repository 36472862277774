import React from 'react'
import SearchResultsComponent from '../../classes/SearchResultsComponent'
import { Link } from "react-router-dom"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Badge from 'react-bootstrap/Badge'
import Container from 'react-bootstrap/Container'
const ReactMarkdown = require('react-markdown/with-html')

class SearchResults extends SearchResultsComponent {

  render() {

    const { entries} = this.state

    if (!entries) return null

    return (
      <Container id="search-results" fluid >
        <div className='query-matches'>{entries.length} matches for <em>{this.state.queryParam}</em></div>
          {entries.map(article => {
            return (
              <Row>
                <Col>
                  <h4><Link to={article.fields.url}>{article.fields.heading}</Link></h4>
                  <div className='tagline'>{article.fields.subtitle}</div>
                  <div id='search-body'>
                    <ReactMarkdown source={article.fields.body} escapeHtml={true}/>
                  </div>
                  {article.fields.tag && article.fields.tag.map(tag => {
                    return (
                      <Link to={"/search?t=" + encodeURIComponent(tag)}>

                      <Badge
                        id='tag'
                        style={{maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden'}}
                        variant="success">
                        {tag}
                      </Badge>
                      </Link>
                    )
                  })}
                  <hr />
                </Col>
              </Row>
            )
          })}
      </Container>
    )
  }
}

export default SearchResults;
