import React, { Component } from 'react'

import { ContentfulContext } from '../../context/context'

import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = 'pk.eyJ1Ijoic2NvdHQtYmZ0IiwiYSI6ImNrbHZhcTNvejA3cXgycG1qemV0ajNoOTYifQ.v_NOk-Hk7gUtyqKKPOizxA';

class Mapbox extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lng:  -3.48111,
      lat: 55.40016,
      zoom: 13
    };
    this.mapContainer = React.createRef();

  }
  componentDidMount() {
    let lng, lat, zoom
    let json = false

    try {
      json = JSON.parse(this.props.section.fields.description)

      lng = json.lng
      lat = json.lat
      zoom = json.zoom
    } catch (error) {
      lng = this.state.lng
      lat = this.state.lat
      zoom = this.state.zoom
    }

    const map = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: 'mapbox://styles/mapbox/outdoors-v11',
      center: [lng, lat],
      zoom: zoom
      });
      
      map.scrollZoom.disable();
  }

  render() {

    return (
      <>
      <h3>{this.props.section.fields.headline}</h3>
      <div className='tagline'>{this.props.section.fields.tagline}</div>
      <div ref={this.mapContainer} className="map-container"/>
      </>
    )

  }
}

Mapbox.contextType = ContentfulContext
export default Mapbox;
