import React from 'react'

import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { useLocation } from "react-router-dom"

export default function Breadcrumbs(props) {

  const {routes} = props
  const separator = '/'
  let location = useLocation();
  const segments = location.pathname.split('/')

  let path = ''
  return (
    <Breadcrumb id='breadcrumbs'>
      {segments.map((segment, i) => {
        path = (path === separator) ? separator + segment : path + separator + segment
        let route = routes.filter(item => item.url === path)
        return <BreadcrumbItem path={path} route={route} key={i}/>
      })
    }
    </Breadcrumb>
  )
}

function BreadcrumbItem(props) {
  const { route } = props

  if (route.length === 0) {
    return (
      <Breadcrumb.Item >
        ...
      </Breadcrumb.Item>
    )
  }

  return (
    <Breadcrumb.Item href={route[0].url}>
      {route[0].headline}
    </Breadcrumb.Item>
  )
}
