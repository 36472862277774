import React, { Component } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import Collapse from 'react-bootstrap/Collapse'
import { NavLink, Redirect } from "react-router-dom"

const location = window.location

class TopMenu extends Component {

  constructor(props) {
    super(props)

    this.state = {
      primary: {headline: '', subMenus: []},
      secondary: {headline: '', subMenus: []},
      searchExpanded: false,
      searchTerm: '',
      redirect: false
    }

    this.handleClick = this.handleClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleKeyUp = this.handleKeyUp.bind(this)
  }

  componentDidMount() {
    let environment = (location.hostname === 'localhost') ? 'develop' : 'staging'
    if (location.hostname === 'https://main.d9ztj2qhxt573.amplifyapp.com' ||
        location.hostname === 'www2.bordersforesttrust.org' ||
        location.hostname === 'bordersforesttrust.org' ||
        location.hostname === 'www.bordersforesttrust.org' ) {environment = 'prod'}

    this.setState({environment: environment})
    fetch('https://bft-static-content-' + environment + '.s3.amazonaws.com/menu.json')
    .then(response => response.json())
    .then(data => {
      this.setState({primary: data.primary[0], secondary: data.secondary[0]})
    })
    .catch(error => {
      console.log(error)
    })
  }

  handleClick(event) {
    if (this.state.searchTerm) {
      this.setState({redirect: true})
    } else {
      this.setState({searchTerm: ''})
    }
    this.setState({searchExpanded: !this.state.searchExpanded})
  }

  handleChange(event) {
    this.setState({searchTerm: event.target.value})
  }

  handleKeyUp(event) {
    if (event.code === 'Enter') {
      this.handleClick(event)
    }
  }

  render() {

    const {primary, secondary, redirect, searchTerm} = this.state

    if (redirect) {
      this.setState({redirect: false, searchTerm: ''})
      return (<Redirect to={"/search?q=" + encodeURIComponent(searchTerm)} />)
    }

    return (
      <>
        <TopMenuSecondary
          primary={primary}
          secondary={secondary}
          handleClick={this.handleClick}
          handleChange={this.handleChange}
          handleKeyUp={this.handleKeyUp}
          searchExpanded={this.state.searchExpanded}
          searchTerm={this.state.searchTerm}
          environment={this.state.environment}
        />
        <TopMenuPrimaryFull primary={primary}/>
      </>
    )
  }
}
//         <TopMenuPrimaryFull primary={primary}/>
export default TopMenu

function TopMenuSecondary(props) {

  const {secondary, primary, handleClick, handleKeyUp, searchExpanded, searchTerm, handleChange} = props
  return (
    <Navbar collapseOnSelect id='secondary-navigation' expand="lg" >
      <Navbar.Brand href="/">
        <img alt="bft logo" src="/images/bft-logo-non-theme-color.png" style={{height:'4rem', marginRight: 10, marginLeft:-24}}/>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" id='bft-toggler'/>
      <DonateButton className="navbar-text donate-collapsed" environment={props.environment}>Donate</DonateButton>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav id="hamburger" className="ml-auto justify-content-right">
          {secondary.subMenus.map((menu, i) => {
            return (
              <span key={i}>
              <MenuItem menu={menu} depth={secondary.depth}/>
              </span>
            )
          })}

          {primary.subMenus.map((menu, i) => {
            return (
              <span key={i} className="donate-collapsed-parent">
              <MenuItem className="donate-collapsed" menu={menu} depth={secondary.depth}/>
              </span>
            )
          })}
        </Nav>
        <SearchWidget handleClick={handleClick} handleChange={handleChange} handleKeyUp={handleKeyUp} searchExpanded={searchExpanded} searchTerm={searchTerm}/>
        <DonateButton className="navbar-text donate-expanded" environment={props.environment}>Donate</DonateButton>
      </Navbar.Collapse>
    </Navbar>
  )
}

function DonateButton(props) {
  if (props.environment === 'prod') {return null}
  return (
    <span className={props.className}>
    <Form inline>
      <Button href="/donate" style={{color: 'white'}} variant="danger" className="mr-sm-2">{props.children}</Button>
    </Form>
    </span>
  )
}

function SearchWidget(props) {
  const {searchExpanded, handleClick, handleChange, handleKeyUp, searchTerm} = props
  return (
    <Form inline onSubmit={e => e.preventDefault()}>
      <Collapse in={searchExpanded} dimension='height'>
        <FormControl id="search-term" type="text" value={searchTerm} placeholder="Search" onKeyUp={handleKeyUp} onChange={handleChange} className="mr-sm-2" />
      </Collapse>
      <Button
        variant="warning"
        onClick={handleClick}
        className="mr-sm-2"
        aria-controls="search-term"
        aria-expanded={searchExpanded}
      >
          <span  style={{color: 'white'}}><i className="fas fa-search" style={{text: 'white'}}></i></span>
      </Button>
    </Form>
  )
}

function handleSelect(props) {
//  alert('here')
}

// To Do: change the span on line 178 to have top and bottom margin of 8px
// when the menu is collapsed (mobile)

function TopMenuPrimaryFull(props) {

  const {primary} = props

  return (
    <Navbar collapseOnSelect id='primary-navigation-full' variant="dark">
      <Nav className="ml-auto justify-content-right" activeKey="/home"  onClick={() => handleSelect(true)}>
        {primary.subMenus.map((menu, i) => {
          return (
            <span key={i}>
            <MenuItem menu={menu} depth={primary.depth}/>
            </span>
          )
        })}
      </Nav>
    </Navbar>
  )
}

function MenuItem(props) {
  const {menu, depth, className} = props

  if (menu.subMenus.length === 0 || depth === 1) {
    if (menu.renderAsButton) {
      return null
    }
    if (menu.internalUrl){
      return (
        <span onClick={handleSelect}>
        <NavLink  className={'nav-link ' + className} to={menu.url}>{menu.headline}</NavLink>
        </span>
      )
    } else if (menu.url) {
      return (
        <a className={'nav-link ' + className} href={menu.url}>{menu.headline}</a>
      )
    } else {
      return (
        <NavLink onClick={handleSelect} className={'nav-link ' + className} to='/'>{menu.headline}</NavLink>
      )
    }
  } else {
    return (
      <NavDropdown title={menu.headline} id="basic-nav-dropdown">
        {menu.subMenus.map((subMenu, i) => {
          let url = subMenu.internalUrl ? subMenu.url : "/"
          return (
            <span key={i}>
            <NavLink onClick={handleSelect} className={'dropdown-item ' + className} to={url}>{subMenu.headline}</NavLink>
            </span>
          )
        })}
      </NavDropdown>
    )
  }
}
