import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Badge from 'react-bootstrap/Badge'

import { Link } from "react-router-dom"

export default function Tags(props) {

  const {article} = props
  return (
    <div id='article-heading-block'>

    {article.fields.showTags && article.fields.tag &&
      <Row id='article-tag-list'>
        <Col>
          {article.fields.tag.map((tag, i) => {
            return (
              <Link to={"/search?t="+tag} key={i}><Badge id='tag' key={tag} variant="success">{tag}</Badge></Link>
            )
          })
          }
        </Col>
      </Row>
    }
  </div>

  )
}
