import React from 'react'
import MultipleItemComponent from '../../classes/MultipleItemComponent'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import CallToAction from '../widgets/CallToAction'

class Hero extends MultipleItemComponent {

  render() {

    const {entries} = this.state
    const {zIndex} = this.props

    if (!entries) return null
    const hero = entries[0]

    let internalLink = hero.fields.callToActionArticle && hero.fields.callToActionArticle.fields.url ?
                         hero.fields.callToActionArticle.fields.url  :
                         false
    // This is the case where there is no URL specified for the article
    if (!internalLink && hero.fields.callToActionArticle) {
      internalLink = "/article/" + hero.sys.id
    }
    let externalLink = hero.fields.callToActionUrl ?
                         hero.fields.callToActionUrl :
                         false

    return (
      <Container id='hero' fluid
        style={{backgroundImage: `url(${hero.fields.image.fields.file.url})`,
               minHeight: this.props.minHeight ?? 500,
               zIndex: zIndex,
              }}
      >


        <Row className="lp" style={{marginLeft: -20, marginTop: 140}}>
          <Col lg={12} md={12}>
            <h1>{hero.fields.headline}</h1>
            <p className="tagline">{hero.fields.tagline}</p>

            {hero.fields.displayCallToAction &&
              <CallToAction
                internalLink={internalLink}
                externalLink={externalLink}
              >
                <i className="fas fa-arrow-right"></i> {hero.fields.callToAction}
              </CallToAction>
            }
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Hero;
