import React from 'react'
import { Link } from "react-router-dom"

export default function CallToAction(props) {

  if (props.internalLink) {

    return (
      <Link
        className="btn-link"
        to={props.internalLink.replace('//', '/')}
        style={{textDecoration: 'none'}}
      >
      {props.children}
      </Link>
    )
  }

  let externalLink = props.externalLink ? props.externalLink.replace('https://', '') : ''
  externalLink = externalLink.replace('http://', '')
  externalLink = externalLink.replace('//', '')
  externalLink = 'https://' + externalLink

  return (
    <a href={externalLink} style={{textDecoration: 'none'}}>
      {props.children}
    </a>
  )
}
