import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import Hero from './sections/Hero'
import ThreeCards from './sections/ThreeCards'
import FourLinks from './sections/FourLinks'
import FourCards from './sections/FourCards'
import FourImages from './sections/FourImages'
import Mapbox from './sections/Mapbox'
import ImageGallery from './sections/ImageGallery'
import Quote from './sections/Quote'
import Footer from './Footer'

import {ContentfulContext, HOME_PAGE_QUERY} from '../context/context'

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      isLoading: true,
      content: false,
      section: []
    };
  }

  componentDidMount() {
    const contentfulClient = this.context.contentfulClient

    contentfulClient.getEntries(HOME_PAGE_QUERY)
    .then(entries => {

      const sections = entries.items[0].fields.sections.map(section => {
        const sectionDetail = entries.includes.Entry.filter(item => item.sys.id === section.sys.id)
        return sectionDetail[0]
      })
      this.setState({
        isLoaded: true,
        isLoading: false,
        sections: sections,
        pages: entries.items[0],
      })
    })
    .catch(error => {
      console.log(error)
      this.setState({
        error: error,
        isLoading: false,
        isLoaded: false,
      })
    })
  }

  render() {
    const {sections} = this.state

    if (!sections) return null

    return (
      <Container fluid>
        <Row>
          {sections.map((section, index) => {
            switch (section.fields.type) {
              case 'Hero':
                return <Hero section={section} minHeight={500} zIndex={index} key={index}/>
              case 'Three Cards':
                return <ThreeCards section={section} zIndex={index} key={index}/>
              case 'Four Links':
                return <FourLinks section={section} zIndex={index} key={index}/>
              case 'Four Cards':
                return <FourCards section={section} zIndex={index} key={index}/>
              case 'Four Images':
                return <FourImages section={section} zIndex={index} key={index}/>
              case 'Map':
                return <Mapbox section={section} key={index}/>
              case 'Image Gallery':
                return <ImageGallery section={section} key={index}/>
              case 'Quote':
                return <Quote section={section} key={index}/>
              default:
                return null
            }
          })}

        </Row>
        <Row>
          <Footer />
        </Row>
      </Container>

    )
  }
}

Home.contextType = ContentfulContext
export default Home;
