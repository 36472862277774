import React, { Component } from "react"
import { API } from 'aws-amplify'

import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Alert from "react-bootstrap/Alert"

class ContactUs extends Component {

  constructor(props) {
    super(props)

    this.state = {
      disabled: true,
      name: '',
      email: '',
      subject: '',
      body: '',
      message: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.submit = this.submit.bind(this)
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const disabled = !isValidEmail(this.state.email) ||
                     this.state.name.length === 0 ||
                     this.state.subject.length === 0

    this.setState({
      [name]: value,
      disabled: disabled
    })
  }


  submit() {
    const body = {
      type: 'contact',
      email: this.state.email,
      name: this.state.name,
      subject: this.state.subject,
      body: this.state.body
    }

    API.post('form', '/submit', {header: {'Referrer-Policy': 'same-origin'}, body: body})
    .then(response => {
      this.setState({
        disabled: true,
        name: '',
        email: '',
        subject: '',
        body: '',
        message: response.message
      })

    })
    .catch(error => {
      console.log(error)
      this.setState({
        disabled: true,
        name: '',
        email: '',
        subject: '',
        body: '',
        message: error.message
      })
    })
  }

  render() {
    const { section } = this.props

    return (
      <Container id="contact-us" fluid style={{marginBottom: 40}}>
        <Row>
          <Col lg={3}>
            <div className='tagline'>{section.fields.tagline}</div>
            <div className='description'>{section.fields.description}</div>
          </Col>
          {this.state.message.length === 0 &&

          <Col lg={6}>
            <Form.Group controlId="formName">
              <Form.Label>Your Name (required)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Your name"
                name='name'
                value={this.state.name}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email address (required)</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name='email'
                value={this.state.email}
                onChange={this.handleChange}
              />
              <Form.Text className="text-muted">
                We will never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Subject (required)</Form.Label>
              <Form.Control
                type="text"
                name="subject"
                value={this.state.subject}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Body</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                name="body"
                value={this.state.body}
                onChange={this.handleChange} />
            </Form.Group>

            <Button
              className='btn btn-warning'
              disabled={this.state.disabled}
              onClick={this.submit}
            >
              Submit
            </Button>
          </Col>
          }
          {this.state.message.length > 0 &&
              <Col lg={6} style={{minHeight: 200}}>
              <Alert variant='primary'>
                {this.state.message}
              </Alert>
              </Col>
          }
        </Row>

    </Container>
  )
  }

}

export default ContactUs

function isValidEmail(email) { // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase()) && !email.includes('%');
}
