import React from 'react'
import SingleItemComponent from '../../classes/SingleItemComponent'

import Card from 'react-bootstrap/Card'
import { Link } from "react-router-dom"

import moment from 'moment'
const ReactMarkdown = require('react-markdown/with-html')

class BlogPostSummary extends SingleItemComponent {

  render() {

    const {article} = this.state

    if (!article) return null

    let publishedAt = article.fields.displayDate ?
                      moment(article.fields.displayDate) :
                      moment(article.sys.updatedAt)

    const imageSource = article.fields.image && article.fields.image.fields.file ?
                        article.fields.image.fields.file.url :
                        this.props.headlineImage

    const url = ('/events/' + article.fields.url).replace('//', '/')

    return (
      <div className="card mb-3 item-left-hand-image" >
        <div className="row g-1">
          <div className="col-md-4">
          {imageSource &&
            <img
              src={imageSource}
              alt={article.fields.altText}
              style={{width: '100%', objectFit: 'cover', objectPosition: 'center', height: 200}}
            />
          }
          </div>
          <div className="col-md-8">
            <h3><Link to={url}>{article.fields.heading}</Link></h3>
            <Card.Body style={{textOverflow: 'ellipsis', overflow: 'hidden', maxHeight: '6rem'}}>
              <ReactMarkdown source={article.fields.body} escapeHtml={true}/>
            </Card.Body>
            <Card.Footer>
              <small className="text-muted">{publishedAt.fromNow()}</small>
            </Card.Footer>
          </div>
        </div>
      </div>
    )
  }
}

export default BlogPostSummary
