import React from 'react'
import LegacyUrlComponent from '../../classes/LegacyUrlComponent'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { Redirect } from "react-router-dom"

class LegacyUrlSuggestion extends LegacyUrlComponent {

  render() {

    const { entry } = this.state
    const { section } = this.props

    if (!entry) return null

    if (entry.fields.automaticallyRedirect) {
      return (
        // To Do add events and blog path.
        <Redirect to={entry.fields.article.fields.url} />
      )
    }

    return (
      <Container fluid>
        <Row>
          <Col lg={12} md={12}>
            <div id="old-website-link">
              <div className='tagline'>{section.fields.headline}</div>
              <p>{section.fields.description}</p>
              <a href={entry.fields.article.fields.url}>
                <span className="fa-stack fa-2x" style={{verticalAlign: 'bottom', marginLeft: 10,  marginRight: 10}}>
                  <i className="fas fa-circle fa-stack-2x"></i>
                  <i className="fas fa-arrow-right fa-stack-1x fa-inverse"></i>
                </span>
                {entry.fields.article.fields.heading}
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default LegacyUrlSuggestion;
