import Amplify from 'aws-amplify'

import {
  BrowserRouter as Router
} from "react-router-dom";

import Container from 'react-bootstrap/Container'

import './App.scss'
import Routes from './Routes'
import TopMenu from './components/TopMenu'
import aws_exports from './aws-exports'

Amplify.configure(aws_exports)

function App() {
  return (
    <Container>
      <Router>
        <TopMenu full/>
        <Routes />
      </Router>
    </Container>
  )
}

export default App
