
import { Component } from 'react';

import {ContentfulContext} from '../context/context'

class MultipleItemComponent extends Component  {
  constructor(props) {
    super(props)

    this.state = {
      error: null,
      isLoaded: false,
      isLoading: true,
      item: false,
      entries: false,
    };
  }

  componentDidMount() {
    const contentfulClient = this.context.contentfulClient
    let items = this.props.section.fields.items

    const promises = items.map(item => contentfulClient.getEntry(item.sys.id))

    Promise.all(promises)
    .then(entries => {

      this.setState({
        isLoaded: true,
        isLoading: false,
        item: this.props.section.fields,
        entries: entries,
      })
    })
    .catch(error => {
      console.log(error)
      this.setState({
        error: error,
        isLoading: false,
        isLoaded: false,
      })
    })
  }
}

MultipleItemComponent.contextType = ContentfulContext
export default MultipleItemComponent
