import React, { Component } from 'react';
import {
  Switch,
} from "react-router-dom"

import Home from './components/Home'
import Article from './components/Article'

// import ScrollToTop from './components/widgets/PageLoading'

import {
  Route,
} from "react-router-dom";

const location = window.location

class Routes extends Component {

  constructor(props) {
    super(props)

    this.state = {
      initialized: false,
      routes: false,
    }
  }

  componentDidMount() {
    let environment = (location.hostname === 'localhost') ? 'develop' : 'staging'
    if (location.hostname === 'https://main.d9ztj2qhxt573.amplifyapp.com' ||
        location.hostname === 'www2.bordersforesttrust.org' ||
        location.hostname === 'bordersforesttrust.org' ||
        location.hostname === 'www.bordersforesttrust.org' ) {environment = 'prod'}


    fetch('https://bft-static-content-' + environment + '.s3.amazonaws.com/routes.json')
    .then(response => response.json())
    .then(data => {
      this.setState({initialized: true, routes: data})
    })
    .catch(error => {
      console.log(error)
    })
  }

  render() {
//          <ScrollToTop />

    const {initialized, routes} = this.state

    if (initialized) {

      const headlineImages = routes.filter(route => route.headlineImage )
      const headlineImage = headlineImages.length > 0 ? headlineImages[0].headlineImage : '/images/default-article-header.jpg'

      const fourOFourList = routes.filter(route => route.type === '404')
      const fourOFour = (fourOFourList.length > 0 ) ? fourOFourList[0] : false
      const location = window.location

      return (
        <Switch>
          {routes.map((route, i) => {
            let routeComponent = null
            switch (route.type) {
              case 'home page':
                routeComponent = <Route exact path={route.url} key={i}
                                    children={
                                      <Home
                                        id={route.id}
                                      />
                                    }
                                 />
                break
              case 'article':
                routeComponent = <Route exact path={route.url} key={i}
                                    children={
                                      <Article
                                        id={route.id}
                                        articleType={route.articleType}
                                        routeType={route.type}
                                        headlineImage={headlineImage}
                                        routes={routes}
                                        search={location.search}
                                      />
                                    }
                                 />
                break
              case 'blog':
              case 'blog-list':
              case 'event':
              case 'event-list':
                routeComponent = <Route exact path={route.url} key={i}
                                    children={
                                        <Article
                                          id={route.id}
                                          headline={route.headline}
                                          type={route.type}
                                          articleType={route.articleType}
                                          headlineImage={headlineImage}
                                          routeType={route.type}
                                          year={route.year}
                                          month={route.month}
                                          routes={routes}
                                        />
                                      }
                                  />
                break
              case '404':
                  // Ignore this
//                routeComponent = <Route exact path={route.url} children={<Article id={route.id}/>} />
                break
              default:
                // do nothing
            }

            return routeComponent
          })}
          {fourOFour &&
            <Route path="*" children={<Article id={fourOFour.id} articleType={fourOFour.articleType} routeType={fourOFour.type}/> } />
          }
        </Switch>
      )
    }

    // A default route until we get some data on the routes.
    return (
      <>
        <Route path='/'>
        </Route>
      </>
    )
  }
}

export default Routes
