import React from 'react';
import SingleItemComponent from '../classes/SingleItemComponent'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'
import PageLoading from './widgets/PageLoading'

import TopImage from './widgets/TopImage'
import ArticleBody from './article/ArticleBody'
import Blog from './article/Blog'
import Footer from './Footer'
import ArticleSidebar from './sidebar/ArticleSidebar'

const layout = {
  sidebar: {body: 9, sidebar: 3},
  full: {body: 12, sidebar: 0}
}

const defaults = {
  'general-article': {
    showSidebar: false,
    showRelatedArticles: true,
    showNavigation: true,
    showTags: true,
    showHeadline: true,
  },
  'blog-home-page': {
    showSidebar: true,
    showRelatedArticles: true,
    showNavigation: true,
    showTags: true,
    showHeadline: true,
  },
  'blog-list': {
    showSidebar: true,
    showRelatedArticles: true,
    showNavigation: true,
    showTags: true,
    showHeadline: true,
  },
  'blog-post': {
    showSidebar: true,
    showRelatedArticles: true,
    showNavigation: true,
    showTags: true,
    showHeadline: true,
  },
  'events-home-page': {
    showSidebar: true,
    showRelatedArticles: true,
    showNavigation: true,
    showTags: true,
    showHeadline: true,
  },
  'event-list': {
    showSidebar: true,
    showRelatedArticles: true,
    showNavigation: true,
    showTags: true,
    showHeadline: true,
  },
  'event': {
    showSidebar: true,
    showRelatedArticles: true,
    showNavigation: true,
    showTags: true,
    showHeadline: true,
  },
  '404': {
    showSidebar: false,
    showRelatedArticles: false,
    showNavigation: false,
    showTags: false,
    showHeadline: true,
  },
}

class Article extends SingleItemComponent {

//       <ScrollToTop />

  render() {
    const {isLoading, isLoaded, article} = this.state
    const {routeType, articleType, id} = this.props

    if (id) {
      if (isLoading) { return (<PageLoading />) }
      if (!isLoaded) { return null }
    }

    let showSidebar = article && article.fields.showSidebar ? defaults[articleType].showSidebar : false

    const widths = showSidebar ? layout.sidebar : layout.full

    if (article && defaults[articleType]) {
      article.fields.showHeadline = article.fields.showHeadline ?? defaults[articleType].showHeadline
      article.fields.showRelatedArticles = article.fields.showRelatedArticles ?? defaults[articleType].showRelatedArticles
      article.fields.showNavigation = article.fields.showNavigation ?? defaults[articleType].showNavigation
      article.fields.showTags = article.fields.showTags ?? defaults[articleType].showTags
    }

    return (
      <Container fluid id='article'>
        <Row style={{marginLeft: -20, marginRight: -20, marginTop: -20}}>
          <TopImage article={article}  {...this.props} />
        </Row>
        <Row >
          <Col lg={widths.body}>
            <Blog {...this.props} articleType={articleType} />
            <ArticleBody
              href={this.state.href}
              article={article}
              default={defaults[articleType]}
              {...this.props} />
          </Col>

          {showSidebar &&
            <Col lg={widths.sidebar}>
              <ArticleSidebar
                article={article}
                articleType={articleType}
                routeType={routeType}
                 {...this.props} />
            </Col>
          }
        </Row>
        <Row style={{marginLeft: -20, marginRight: -20}}>
          <Footer />
        </Row>
      </Container>
    )
  }
}


export default Article;
