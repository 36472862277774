import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { NavLink} from "react-router-dom"

export default function RelatedItems(props) {
  if (!props.relatedArticles) {return null}
    return (
      <div id='article-related-embedded'>
        <h3>More From Borders Forest Trust</h3>
        <Row >
          {props.relatedArticles.map((article, i) => {
            return (
              <Col lg={6} style={{marginTop: 5}} key={i}>
                <NavLink to={article.fields.url}>{article.fields.heading}</NavLink>
              </Col>
            )
          })
          }
        </Row>
      </div>
    )
}
