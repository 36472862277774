import Badge from 'react-bootstrap/Badge'
import { Link} from "react-router-dom"

export default function Categories(props) {
  const {routes} = props

  const reducer = (accumulator, currentValue) => accumulator.concat(currentValue)
  const tags = routes.map(item => item.tags).reduce(reducer)
  const tagList = tags.filter(onlyUnique).sort((a, b) => {
    if (a.toLowerCase() < b.toLowerCase()) {return -1}
    if (a.toLowerCase() > b.toLowerCase()) {return 1}
    return 0
  })

  return (
    <>
    {tagList.map(item => {
      if (!item) {return null}
      return (
        <span>
          <Link to={"/search?t=" + encodeURIComponent(item)}>
            <Badge
              id='tag'
              style={{maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden'}}
              variant="success">
              {item}
            </Badge>
          </Link>
        </span>
      )
    })}
    </>
  )
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
