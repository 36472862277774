import { Link} from "react-router-dom"
import moment from 'moment'

export default function UpComing(props) {
  const {routes} = props

  const recent = routes
    .filter(a => moment().isBefore(moment(a.publishedAt)))
    .sort((a, b) => {
      if (a.publishedAt < b.publishedAt) {return -1}
      if (a.publishedAt > b.publishedAt) {return 1}
      return 0
    }).slice(0, 5)

  return (
    <span  id="sidebar-list">
      <h3>Up Coming</h3>
      <ul>
      {recent.map(item => {
        return (
          <li>
            <Link to={item.url}>{item.headline}</Link>
          </li>
        )
      })}
      </ul>
    </span>
  )
}
