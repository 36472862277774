import React from 'react'
import MultipleItemMenuComponent from '../../classes/MultipleItemMenuComponent'

import CallToAction from '../widgets/CallToAction'

class FooterHorizontalMenu extends MultipleItemMenuComponent {

  render() {

    const {entries} = this.state

    if (!entries) return null

    return (
      <>
      {entries.map((item, i) => {
        let externalLink = item.fields.url ?? false
        let url = item.fields.url
        if (item.fields.page) {
          url = item.fields.page.fields && (item.fields.page.fields.url ?? false)
          if (url && item.fields.page.fields.type === 'Blog Post') {
            url = 'blog/' + url
          }
          if (url && item.fields.page.fields.type === 'Event') {
            url = 'events/' + url
          }
        }
        return (
          <CallToAction
            internalLink={url}
            externalLink={externalLink}
            key={i}
          >
            {item.fields.headline}
          </CallToAction>
        )
      })}
      </>
    )

  }
}

export default FooterHorizontalMenu
