export default function TopImage(props) {

  const {article, headlineImage} = props

  if (!article && !headlineImage) return null

  const useArticleImage = article && article.fields.image && article.fields.image.fields.file

  return (
    <>
      {useArticleImage &&
        <div id='article-top-image-row'>

            <img
              id='article-top-image'
              alt={article.fields.image.fields.title}
              src={article.fields.image.fields.file.url}
              //set banner for computer browers and scale down for mobile
              //width='100%'
              //on computer - menues sitting over image
              width='100%'
              height={500}

            />

        </div>
      }
      {!useArticleImage && headlineImage &&

        <div id='article-top-image-row'>

            <img
              id='article-top-image'
              alt='bft'
              src={headlineImage}
              width='100%'
              height={500}
            />

        </div>

      }
    </>
  )
}
