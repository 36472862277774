import React from 'react'

import {ContentfulContext} from '../context/context'

class SingleItemComponent extends React.Component  {

  constructor(props) {
    super(props)

    this.state = {
      id: false,
      error: null,
      isLoaded: false,
      isLoading: false,
      article: false,
      href: false
    }

    this.loadData = this.loadData.bind(this)
  }

  componentDidMount() {
    if (!this.state.href) {this.loadData()}
  }

  componentDidUpdate() {
    if (this.state.href && this.state.href !== window.location.href) {this.loadData()}
  }

  loadData() {
    const contentfulClient = this.context.contentfulClient

    if (!this.props.id) return
    if (this.props.id === this.state.id) return
    if (this.state.isLoading) return

    this.setState((state, props) => ({
      isLoading: true,
      id: this.props.id
    }))
    contentfulClient.getEntry(this.props.id)
    .then(entry => {
      this.setState({
        isLoaded: true,
        isLoading: false,
        article: entry,
        href: window.location.href
      })
    })
    .catch(error => {
      console.error(error)
      this.setState({
        isLoading: false,
        isLoaded: false,
        error: error,
        href: window.location.href
      })
    })
  }
}

SingleItemComponent.contextType = ContentfulContext
export default SingleItemComponent
