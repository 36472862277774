import React from 'react'
import MultipleItemComponent from '../../classes/MultipleItemComponent'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

class FourImages extends MultipleItemComponent {

  render() {

    const {item, entries} = this.state
    const {zIndex} = this.props

    if (!entries) return null

    let topEdgeTopStyle = {}

    switch (item.roughEdgeTop) {
      case 'Off White':
        topEdgeTopStyle = {
          marginTop: -30,
          paddingTop: '1rem',
          backgroundImage: `url("/images/rough-edge-off-white.png")`,
          backgroundRepeat: "no-repeat",
          zIndex: zIndex
        }
        break
      case 'Lime Green':
        topEdgeTopStyle = {
          marginTop: -30,
          paddingTop: '1rem',
          backgroundImage: `url("/images/rough-edge-lime-green.png")`,
          backgroundRepeat: "no-repeat",
          zIndex: zIndex
        }
        break
      default:
      topEdgeTopStyle = {
        paddingTop: '1rem',
        backgroundColor: '#f3f1e6', // To Do. Make these styles classes.
        zIndex: zIndex
      }
    }

    return (
      <Container
        id='four-images'
        fluid
        style={topEdgeTopStyle}
      >
        <Row >
          <Col lg={{ span: 10, offset: 1 }}>
            <div className='tagline'>{item.tagline}</div>
          </Col>
        </Row>
        <Row className='justify-content-center instagram-link'>
          <Col lg={4} md={6}>
            <div style={{textAlign: 'center'}}>
              <a href="https://www.instagram.com/bordersforesttrust/">
                <i className="fab fa-instagram" style={{verticalAlign: 'baseline'}}></i> bordersforesttrust
              </a>
            </div>
          </Col>
        </Row>
        <Row style={{paddingLeft: 0, paddingRight: 0}}>
          {entries.map((item, i) =>
            <Col lg={3} key={i}>
              <img
                src={item.fields.image.fields.file.url}
                alt={item.fields.altText}
                style={{height: 250, width: '100%', objectFit: 'cover'}} />

              </Col>
            )
          }
        </Row>
      </Container>
    )
  }
}

export default FourImages;
