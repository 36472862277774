import { Link} from "react-router-dom"

export default function Archives(props) {
  const {routes} = props
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const archives = routes.filter(a => a.articleType === 'blog-list').sort((a, b) => {
    if (a.year + a.month > b.year + b.month) {return -1}
    if (a.year + a.month < b.year + b.month) {return 1}
    return 0
  })

  const years = archives.filter(item => !item.month && item.year)

  return (
    <span  id="sidebar-list">
    <ul>
      <li><Link to='/blog'>Blog Home</Link></li>
      { years.map(year => {
        return (
          <li>
            <Link to={year.url}>{year.year}</Link>
            <ul>
            {archives.filter(month => (month.year === year.year && month.month)).map(item => {
              return (
                <li style={{paddingLeft: 10}}>
                  <Link to={item.url}>{monthNames[item.month - 1]}</Link>
                </li>
              )
            })}
            </ul>
          </li>
          )
      })
    }
    </ul>
    </span>
  )
}
