import { useLocation } from "react-router-dom"

import Hero from '../sections/Hero'
import ThreeCards from '../sections/ThreeCards'
import FourLinks from '../sections/FourLinks'
import FourCards from '../sections/FourCards'
import FourImages from '../sections/FourImages'
import BlogPostList from '../sections/BlogPostList'
import EventList from '../sections/EventList'
import Mapbox from '../sections/Mapbox'
import ImageGallery from '../sections/ImageGallery'
import Quote from '../sections/Quote'
import SiteMap from '../sections/SiteMap'
import ImageAndText from '../sections/ImageAndText'
import BlogRelatedItems from '../sections/BlogRelatedItems'
import ContactUs from '../sections/ContactUs'
import LegacyUrlSuggestion from '../sections/LegacyUrlSuggestion'
import SearchResults from '../sections/SearchResults'

export default function Sections(props) {
  const {sections, routes, headlineImage} = props

  let location = useLocation();
  if (!sections) return null
  return (
    <div style={{marginLeft: -20, marginRight: -20}}>
      {sections.map((section, i) => {
        const type = section.fields.type ? section.fields.type : null
        switch (type) {
          case 'Hero':
            return <Hero section={section} key={i}/>
          case 'Three Cards':
            return <ThreeCards section={section} key={i}/>
          case 'Four Links':
            return <FourLinks section={section} key={i}/>
          case 'Four Cards':
            return <FourCards section={section} key={i}/>
          case 'Four Images':
            return <FourImages section={section} key={i}/>
          case 'Blog Post List':
            return <BlogPostList routes={routes} headlineImage={headlineImage} key={i}/>
          case 'Event List':
            return <EventList routes={routes} headlineImage={headlineImage} key={i}/>
          case 'Blog Post Embedded List':
            return <BlogRelatedItems routes={routes} section={section} key={i}/>
          case 'Map':
            return <Mapbox section={section} key={i}/>
          case 'Image Gallery':
            return <ImageGallery section={section} key={i}/>
          case 'Quote':
            return <Quote section={section} key={i}/>
          case 'Site Map':
            return <SiteMap section={section} routes={routes} key={i}/>
          case 'Image Left and Text':
            return <ImageAndText section={section} imageLocation='left' headlineImage={headlineImage} key={i}/>
          case 'Image Right and Text':
            return <ImageAndText section={section} imageLocation='right' headlineImage={headlineImage} key={i}/>
          case 'Contact Us':
            return <ContactUs section={section} key={i}/>
          case 'Legacy Url Suggestion':
            return <LegacyUrlSuggestion route={location} section={section} key={i}/>
          case 'Search Results':
            return <SearchResults route={location} section={section} search={location.search} key={i}/>
          default:
            return null
        }
      })}
    </div>
  )
}
