
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BlogPostSummary from '../widgets/BlogPostSummary'

export default function Blog(props) {
  const {routes, headline, articleType, year, month, headlineImage} = props
  if (articleType !== 'blog-list') return null

  const postsSorted = routes ?
      routes.sort((a, b) => {
        if (a.publishedAt < b.publishedAt) {return 1}
        if (a.publishedAt > b.publishedAt) {return -1}
        return 0
      }) :
      []

  return (
    <div id='article-heading-block'>
      <Row>
        <Col>
          <h2>{headline}</h2>
        </Col>
      </Row>
      <Row>
        {year && postsSorted.map(route => {
          if ((route.year !== year) ||
              (month && route.month !== month) ||
               route.type === articleType) {return null}
          if (route.articleType !== 'blog-post') {return null}
          return (
              <BlogPostSummary id={route.id} headlineImage={headlineImage}/>
          )
        })}
      </Row>
    </div>
  )
}
