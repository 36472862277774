import React, { Component } from 'react';

import GlobalFooter from './sections/GlobalFooter'

import {ContentfulContext, GLOBAL_FOOTER_1_QUERY, GLOBAL_FOOTER_2_QUERY} from '../context/context'

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      globalFooter1: false,
      globalFooter2: false
    };
  }

  componentDidMount() {
    const contentfulClient = this.context.contentfulClient

    contentfulClient.getEntries(GLOBAL_FOOTER_1_QUERY)
    .then(entries => {
      this.setState({
        globalFooter1: entries.items[0],
      })
    })
    .catch(error => {
      console.log(error)
      this.setState({
        error: error,
      })
    })

    contentfulClient.getEntries(GLOBAL_FOOTER_2_QUERY)
    .then(entries => {
      this.setState({
        globalFooter2: entries.items[0],
      })
    })
    .catch(error => {
      console.log(error)
      this.setState({
        error: error,
      })
    })
  }

  render() {
    const {globalFooter1, globalFooter2} = this.state

    if (!globalFooter1 || !globalFooter2) return null

    return (
      <div id='footer'>
        {globalFooter1 && <GlobalFooter section={globalFooter1}/>}
        {globalFooter2 && <GlobalFooter section={globalFooter2}/>}
      </div>

    )
  }
}


Footer.contextType = ContentfulContext
export default Footer;
