import React from 'react'

import {ContentfulContext, LEGACY_URL} from '../context/context'

class LegacyUrlComponent extends React.Component  {

  constructor(props) {
    super(props)

    this.state = {
      error: null,
      isLoaded: false,
      isLoading: false,
      article: false
    }
  }

  componentDidMount() {
    const contentfulClient = this.context.contentfulClient

    if (!this.props.route) return

    this.setState({isLoading: true})
    const query = LEGACY_URL(this.props.route.pathname)
    contentfulClient.getEntries(query)
    .then(entries => {
      this.setState({
        isLoaded: true,
        isLoading: false,
        entry: entries.items ? entries.items[0] : false,
      })
    })
    .catch(error => {
      console.error(error)
      this.setState({
        isLoading: false,
        isLoaded: false,
        error: error,
      })
    })

  }
}

LegacyUrlComponent.contextType = ContentfulContext
export default LegacyUrlComponent
