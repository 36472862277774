import { Link} from "react-router-dom"

import Recent from "./Recent"
import Categories from "./Categories"
import Archives from "./Archives"
import UpComing from "./UpComing"

export default function ArticleSidebar(props) {

  const {routes, articleType} = props

  // let type = 'blog-p'
  // if (articleType === 'events home page' || articleType === 'Event') {type = 'event'}

  let filteredRoutes

  if (articleType === 'event' || articleType === 'event-list' || articleType === 'events-home-page') {

    filteredRoutes = routes.filter(route => route.articleType === 'event')

    return (
      <>
      <UpComing routes={filteredRoutes} />
      <h3>Categories</h3>
      <Categories routes={filteredRoutes} />
      <h3>Events</h3>
      <Link to='/events' style={{textDecoration: 'none'}}>Events Home</Link>
      </>
    )
  }

  if (articleType === 'blog-post' || articleType === 'blog-list' || articleType === 'blog-home-page') {

    filteredRoutes = routes.filter(route => route.articleType === 'blog-post')

    return (
      <>
      <Recent routes={filteredRoutes} />
      <h3>Categories</h3>
      <Categories routes={filteredRoutes} />
      <h3>Archives</h3>
      <Archives routes={routes} />
      </>
    )
  }

  return null
}
