import React from "react"
import MultipleItemComponent from '../../classes/MultipleItemComponent'

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import CallToAction from '../widgets/CallToAction'


class Quote extends MultipleItemComponent {

  render() {

    const {item, entries} = this.state
    const {zIndex} = this.props

    if (!entries) return null

    const entry = entries[0]
    let topEdgeTopStyle = {
      paddingTop: '1rem',
      backgroundColor: '#f3f1e6' // To Do. Make these styles classes.
    }

    switch (item.roughEdgeTop) {
      case 'Off White Hero':
        topEdgeTopStyle = {
          marginTop: -80,
          paddingTop: 40,
          backgroundImage: `url("/images/rough-edge-off-white.png")`,
          backgroundRepeat: "no-repeat",
          zIndex: zIndex
        }
        break
      case 'Lime Green Hero':
        topEdgeTopStyle = {
          marginTop: -80,
          paddingTop: 40,
          backgroundImage: `url("/images/rough-edge-lime-green.png")`,
          backgroundRepeat: "no-repeat",
          zIndex: zIndex
        }
        break
      case 'Off White':
        topEdgeTopStyle = {
          marginTop: -30,
          paddingTop: 35,
          backgroundImage: `url("/images/rough-edge-off-white.png")`,
          backgroundRepeat: "no-repeat",
          zIndex: zIndex
        }
        break
      case 'Lime Green':
        topEdgeTopStyle = {
          marginTop: -30,
          paddingTop: 35,
          backgroundImage: `url("/images/rough-edge-lime-green.png")`,
          backgroundRepeat: "no-repeat",
          zIndex: zIndex
        }
        break
      default:
    }

    let callToAction = entry.fields.displayCallToAction ?? false
    let url = false
    if (callToAction && entry.fields.callToActionArticle) {
      url = entry.fields.callToActionArticle.fields && entry.fields.callToActionArticle.fields.url ? entry.fields.callToActionArticle.fields.url : false
      if (url && entry.fields.callToActionArticle.fields.type === 'Blog Post') {
        url = 'blog/' + url
      }
      if (url && entry.fields.callToActionArticle.fields.type === 'Event') {
        url = 'events/' + url
      }
    }

    return (
      <Container id="four-cards" fluid
      style={topEdgeTopStyle}
      >
      <Row style={{paddingBottom: 20}}>
        <Col  md={12}>
          <Container id="quote" fluid
            style={topEdgeTopStyle}
          >
            <div className='tagline'>{entry.fields.tagline}</div>
            {callToAction &&
              <div className='call-to-action'>
              <CallToAction
                internalLink={url}
                externalLink={entry.fields.callToActionUrl}
              >                                  <span className="fa-stack fa-2x" style={{verticalAlign: 'bottom', marginBottom: '-1rem'}}>
                                                  <i className="fas fa-circle fa-stack-2x"></i>
                                                  <i className="fas fa-arrow-right fa-stack-1x fa-inverse"></i>
                                                </span>
                 {entry.fields.callToAction}
              </CallToAction>
              </div>
            }
          </Container>
        </Col>
      </Row>
      </Container>
    )
  }
}

export default Quote;
