import React from "react"
import MultipleItemComponent from '../../classes/MultipleItemComponent'

import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import CallToAction from '../widgets/CallToAction'

import { Link } from "react-router-dom"

class ImageAndText extends MultipleItemComponent {

  render() {

    const {item, entries } = this.state
    const {zIndex, imageLocation} = this.props

    if (!entries) return null

    const entry = entries[0]
    let topEdgeTopStyle = {
      paddingTop: '1rem',
      backgroundColor: '#f3f1e6' // To Do. Make these styles classes.
    }

    const imageClassName = imageLocation === 'left' ? "col-md-4 order-first" : "col-md-4 order-xs-first order-sm-first order-md-last"

    switch (item.roughEdgeTop) {
      case 'Off White Hero':
        topEdgeTopStyle = {
          marginTop: -80,
          paddingTop: 40,
          backgroundImage: `url("/images/rough-edge-off-white.png")`,
          backgroundRepeat: "no-repeat",
          zIndex: zIndex
        }
        break
      case 'Lime Green Hero':
        topEdgeTopStyle = {
          marginTop: -80,
          paddingTop: 40,
          backgroundImage: `url("/images/rough-edge-lime-green.png")`,
          backgroundRepeat: "no-repeat",
          zIndex: zIndex
        }
        break
      case 'Off White':
        topEdgeTopStyle = {
          marginTop: -30,
          paddingTop: 35,
          backgroundImage: `url("/images/rough-edge-off-white.png")`,
          backgroundRepeat: "no-repeat",
          zIndex: zIndex
        }
        break
      case 'Lime Green':
        topEdgeTopStyle = {
          marginTop: -30,
          paddingTop: 35,
          backgroundImage: `url("/images/rough-edge-lime-green.png")`,
          backgroundRepeat: "no-repeat",
          zIndex: zIndex
        }
        break
      default:
    }

    let callToAction = entry.fields.displayCallToAction ?? false
    let url = false
    if (callToAction && entry.fields.callToActionArticle) {
      url = entry.fields.callToActionArticle.fields && entry.fields.callToActionArticle.fields.url ? entry.fields.callToActionArticle.fields.url : false
      if (url && entry.fields.callToActionArticle.fields.type === 'Blog Post') {
        url = 'blog/' + url
      }
      if (url && entry.fields.callToActionArticle.fields.type === 'Event') {
        url = 'events/' + url
      }
    }
    const imageSource = entry.fields.image && entry.fields.image.fields.file ?
                        entry.fields.image.fields.file.url :
                        this.props.headlineImage

    return (
      <>
      <Container id="image-and-text" fluid
      style={topEdgeTopStyle}
      >
        <div className="card mb-3 item-left-hand-image" >
          <div className="row g-1">
            <div className="col-md-8">
              <h3><Link style={{textDecoration: 'none'}} to={url}>{entry.fields.headline}</Link></h3>
              <p className='tagline'>{entry.fields.tagline}</p>
              <Card.Body style={{textOverflow: 'ellipsis', overflow: 'hidden', maxHeight: '6rem', width: '100%'}}>
                {entry.fields.body}
              </Card.Body>
              <Card.Footer>
                {callToAction &&
                  <div className='call-to-action'>
                    <CallToAction
                      internalLink={url}
                      externalLink={entry.fields.callToActionUrl}
                    >
                     <span className="fa-stack fa-2x" style={{verticalAlign: 'bottom', marginBottom: '-1rem'}}>
                        <i className="fas fa-circle fa-stack-2x"></i>
                        <i className="fas fa-arrow-right fa-stack-1x fa-inverse"></i>
                      </span>
                     {entry.fields.callToAction}
                    </CallToAction>
                  </div>
                }
              </Card.Footer>
            </div>
            <div className={imageClassName}>
            {imageSource &&
              <img
                src={imageSource}
                alt={entry.fields.altText}
                style={{width: '100%', objectFit: 'cover', objectPosition: 'center', minHeight: '100%'}}
              />
            }
            </div>
          </div>

        </div>
      </Container>

      </>
    )
  }
}

export default ImageAndText;
