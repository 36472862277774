import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import RelatedItems from '../sections/RelatedItems'

export default function RelatedArticles(props) {

  let {showRelatedArticles, relatedArticles} = props
  if (!showRelatedArticles || !relatedArticles) return null

  return (
    <Row>
      <Col>
        <RelatedItems relatedArticles={relatedArticles} />
      </Col>
    </Row>
  )
}
