import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { NavLink} from "react-router-dom"

export default function BlogRelatedItems(props) {

  const {routes, section} = props

  const postsSorted = routes.sort((a, b) => {
    if (a.publishedAt < b.publishedAt) {return 1}
    if (a.publishedAt > b.publishedAt) {return -1}
    return 0
  })
  return (
    <Col>
      <div id='article-related-embedded'>
        <h3>{section.fields.headline}</h3>
        <Row>
          {postsSorted.map(route => {
            if (route.articleType !== 'blog-post') {return null}
            return (
              <Col lg={6} style={{marginTop: 5}}>
                <NavLink to={route.url}>{route.headline}</NavLink>
              </Col>
            )
          })}
        </Row>
      </div>
    </Col>
  )
  //
  // if (!props.relatedArticles) {return null}
  //   return (
  //     <div id='article-related-embedded'>
  //       <h3>More From Borders Forest Trust</h3>
  //       <Row >
  //         {props.relatedArticles.map(article => {
  //           return (
  //             <Col lg={6} style={{marginTop: 5}}>
  //               <NavLink to={article.fields.url}>{article.fields.heading}</NavLink>
  //             </Col>
  //           )
  //         })
  //         }
  //       </Row>
  //     </div>
  //   )
}
