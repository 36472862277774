import React from 'react'
import MultipleItemComponent from '../../classes/MultipleItemComponent'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import CallToAction from '../widgets/CallToAction'

class FourCards extends MultipleItemComponent {

  render() {

    const {item, entries} = this.state
    const {zIndex} = this.props

    if (!entries) return null

    return (
      <Container id="four-cards" fluid
      style={{backgroundImage: `url(${item.image.fields.file.url})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              textAlign: 'left',
              verticalAlign: 'middle',
              zIndex: zIndex,
            }}
      >
        <Row className='lp rp'>
          <Col style={{marginTop: '2rem', marginBottom: '2rem', padding: 0}}>
            <Row>
              <Col lg={4}>
                <h2>{item.tagline}</h2>
              </Col>
              <Col lg={8}>
                <Row>

                  {entries.map((item, i) => {
                    let callToAction = item.fields.displayCallToAction ?? false
                    let url = false
                    if (callToAction && item.fields.callToActionArticle) {
                      url = item.fields.callToActionArticle.fields && item.fields.callToActionArticle.fields.url ? item.fields.callToActionArticle.fields.url : false
                      if (url && item.fields.callToActionArticle.fields.type === 'Blog Post') {
                        url = 'blog/' + url
                      }
                      if (url && item.fields.callToActionArticle.fields.type === 'Event') {
                        url = 'events/' + url
                      }
                    }
                    return (
                      <Col lg={6} md={6} sm={12} xs={12} style={{paddingBottom: "1.5rem"}} key={i}>
                        <Card className="card h-100" >
                          <Card.Body>
                            <h2>{item.fields.headline}</h2>
                            <table style={{verticalAlign: 'bottom'}}>
                              <tbody>
                              <tr>
                                <td style={{verticalAlign: 'bottom', width: '90%'}}>
                                  <p>{item.fields.tagline}</p>
                                </td>
                                <td style={{verticalAlign: 'bottom'}}>
                                  {item.fields.displayCallToAction &&
                                    <CallToAction
                                      internalLink={url}
                                      externalLink={item.fields.callToActionUrl}
                                    >
                                      <span className="fa-stack fa-2x" style={{verticalAlign: 'bottom', marginBottom: '-1rem'}}>
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fas fa-arrow-right fa-stack-1x fa-inverse"></i>
                                      </span>
                                    </CallToAction>
                                  }
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </Card.Body>
                        </Card>
                      </Col>
                    )
                })}

                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default FourCards;
