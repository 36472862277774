
import { Component } from 'react';

import {ContentfulContext, TAG_SEARCH, CONTENT_SEARCH} from '../context/context'

class SearchResultsComponent extends Component  {
  constructor(props) {
    super(props)

    this.state = {
      error: null,
      isLoaded: false,
      isLoading: true,
      item: false,
      entries: false,
      searchTerm: props.search,
    };
  }

  componentDidMount() {
    this.loadData()

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.route.search !== this.props.route.search) {
      this.setState({entries: false})
      this.loadData()
    }
  }

 loadData() {
   const contentfulClient = this.context.contentfulClient

   let queryParam = ''
   let query
   if (this.props.route.search) {
     if (this.props.route.search.indexOf('?q=') === 0) {
       queryParam = decodeURIComponent(this.props.route.search.replace('?q=', ''))
       query =  CONTENT_SEARCH(queryParam)
     } else if (this.props.route.search.indexOf('?t=') === 0) {
       queryParam = decodeURIComponent(this.props.route.search.replace('?t=', ''))
       query =  TAG_SEARCH(queryParam)
     }
   }

   contentfulClient.getEntries(query)
   .then(entries => {
     this.setState({
       isLoaded: true,
       isLoading: false,
       entries: entries.items ? entries.items : false,
       queryParam: queryParam
     })
   })
   .catch(error => {
     console.error(error)
     this.setState({
       isLoading: false,
       isLoaded: false,
       error: error,
       queryParam: queryParam
     })
   })
 }

}

SearchResultsComponent.contextType = ContentfulContext
export default SearchResultsComponent
