import React, { Component } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BlogPostSummary from '../widgets/BlogPostSummary'

class BlogPostList extends Component {

  render() {

    const {routes, headlineImage} = this.props

    const postsSorted = routes.sort((a, b) => {
      if (a.publishedAt < b.publishedAt) {return 1}
      if (a.publishedAt > b.publishedAt) {return -1}
      return 0
    })
    return (
      <Col>
        <div id='article-heading-block'>
          <Row>
            {postsSorted.map(route => {
              if (route.articleType !== 'blog-post') {return null}
              return (
                  <BlogPostSummary id={route.id} headlineImage={headlineImage}/>
              )
            })}
          </Row>
        </div>
      </Col>
    )
  }
}

export default BlogPostList;
