import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const ReactMarkdown = require('react-markdown/with-html')

export default function Body(props) {

  let {body, escapeHtml} = props
  if (!body) return null

  return (
    <Row>
      <Col lg={12}>
        <ReactMarkdown source={body} escapeHtml={escapeHtml}/>
      </Col>
    </Row>
  )
}
