import moment from 'moment'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Body from './Body'
import Sections from './Sections'
import RelatedArticles from './RelatedArticles'
import Tags from './Tags'
import Breadcrumbs from '../widgets/Breadcrumbs'

export default function ArticleBody(props) {
  const {article, routeType, routes} = props
  if (routeType !== 'article' && routeType !== '404') return null

  let publishedAt = article.fields.displayDate ? moment(article.fields.displayDate) : moment(article.sys.updatedAt);

  const headlineSpacing = article.fields.showHeadline && article.fields.heading ? 0 : -20

  return (
    <>
      <div id='article-heading-block'>
        <Row>
          <Col>
              {article.fields.showNavigation &&
                <Breadcrumbs routes={routes}/>
              }
          </Col>
        </Row>
        <Row style={{marginTop: headlineSpacing}}>
          <Col>
          {article.fields.showHeadline && article.fields.heading &&
            <h2>{article.fields.heading}</h2>
          }
          {article.fields.subtitle &&
             <div className='tagline'>{article.fields.subtitle}</div>
          }
          {(article.fields.type === 'Blog Post' || article.fields.type === 'Event') &&
            <p style={{fontSize: 'smaller'}}>{publishedAt.format("dddd, MMMM Do YYYY")}</p>
          }
          </Col>
        </Row>
      </div>

      <Body body={article.fields.body} escapeHtml={false} />
      <Sections sections={article.fields.sections} {...props} />
      <Body body={article.fields.body1} escapeHtml={false} />
      <Sections sections={article.fields.sections2} />
      <Body body={article.fields.bodyPart3} escapeHtml={false} />
      <RelatedArticles
        showRelatedArticles={article.fields.showRelatedArticles}
        relatedArticles={article.fields.relatedArticles}
      />
      <Tags article={article} />
    </>
  )
}
