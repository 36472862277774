import React, { Component } from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Gallery from 'react-grid-gallery'
import Col from "react-bootstrap/Col"



class ImageGallery extends Component {

  render() {

    const {section} = this.props

    let images = section.fields.imageGallery.map(image => {

      let thumbnailWidth = Math.min(300, image.fields.file.width)
      let thumbnailHeight = image.fields.file.width / image.fields.file.height *thumbnailWidth
      return {
        src: image.fields.file.url,
        thumbnail: image.fields.file.url,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        caption: image.fields.title
      }
    })

    return (
      <Row style={{paddingBottom: 20}}>
      <Col>
      <Container
        id='image-gallery'
        fluid
      >
      <h3>{this.props.section.fields.headline}</h3>
      <div className='tagline'>{this.props.section.fields.tagline}</div>
      <p >{this.props.section.fields.description}</p>
      <Gallery enableImageSelection={false} images={images} backdropClosesModal={true}/>

      </Container>
      </Col>
      </Row>
    )
  }
}

export default ImageGallery;
