import React from "react"
import { API } from 'aws-amplify';

import MultipleItemComponent from '../../classes/MultipleItemComponent'

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import CallToAction from '../widgets/CallToAction'
import FooterHorizontalMenu from '../widgets/FooterHorizontalMenu'

const ReactMarkdown = require('react-markdown/with-html')

class GlobalFooter extends MultipleItemComponent {

  constructor(props) {
    super(props)

    this.state = {
      email: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  handleChange(event) {
    const target = event.target
    const name = target.name.split('.')
    let value = target.type === 'checkbox' ? target.checked : target.value

    this.setState({[name]: value})
  }

  submitForm() {
    API.post('form', '/submit',
             {header: {'Referrer-Policy': 'same-origin'},
              body: {email: this.state.email, type: 'signUp'}})
    .then(response => {
      this.setState({email: ''})
      alert(response.message)
    })
    .catch(error => {
      console.log(error)
      this.setState({email: ''})
      alert(error.message)
    })
  }


  render() {

    const { entries } = this.state

    if (!entries) return null

    return (
      <Container fluid>
      <Row style={{paddingBottom: 20}}>
        {entries.slice(0, 3).map((entry, i) => {
          return (
            <Col lg={4} md={6} sm={12} key={i}>
              <FooterContent entry={entry} />
              <FooterDonate entry={entry} />
              <FooterMenu entry={entry} />
              <FooterRooted entry={entry} />
              <FooterSignUp
                entry={entry}
                email={this.state.email}
                validEmail={isValidEmail(this.state.email)}
                handleChange={this.handleChange}
                submitForm={this.submitForm}
              />
            </Col>
          )
        })}
      </Row>
      </Container>
    )
  }
}

export default GlobalFooter;

function FooterRooted(props) {
  const {entry} = props
  const renderHints = entry.fields.renderHints ?? []
  if (renderHints.indexOf('Rooted in the Community') === -1) {return null}

  return (
    <img src="/images/rooted-in-the-community.png" alt="Rooted in the Community"/>
  )
}

function FooterContent(props) {
  const {entry} = props

  const renderHints = entry.fields.renderHints ?? []
  if (renderHints.indexOf('Content') === -1) {return null}

  return (
    <>
    {entry.fields.tagline ?
       <div className='headline' >{entry.fields.tagline}</div> :
       <div className='headline' style={{marginBottom: -10}}>{entry.fields.tagline}</div>
    }
    <ReactMarkdown source={entry.fields.body} escapeHtml={false}/>
    </>
  )
}


function FooterMenu(props) {
  const {entry} = props

  if (entry.sys.contentType.sys.id !== 'menu') {return null}

  return (
    <FooterHorizontalMenu entry={entry} />
  )
}

function FooterDonate(props) {
  const {entry} = props

  const renderHints = entry.fields.renderHints ?? []
  if (renderHints.indexOf('Donate') === -1) {return null}

  let callToAction = entry.fields.displayCallToAction ?? false
  let url = false
  if (callToAction && entry.fields.callToActionArticle) {
    url = entry.fields.callToActionArticle.fields && entry.fields.callToActionArticle.fields.url ? entry.fields.callToActionArticle.fields.url : false
    if (url && entry.fields.callToActionArticle.fields.type === 'Blog Post') {
      url = 'blog/' + url
    }
    if (url && entry.fields.callToActionArticle.fields.type === 'Event') {
      url = 'events/' + url
    }
  }

  return (
    <>
      <div className='headline'>{entry.fields.headline}</div>
      <ReactMarkdown source={entry.fields.body} escapeHtml={false}/>
      <CallToAction
        internalLink={url}
        externalLink={entry.fields.callToActionUrl}
      >
        <Button variant='warning' style={{marginBottom: 10}}>{entry.fields.callToAction}</Button>
      </CallToAction>
    </>
  )
}

function FooterSignUp(props) {
  const {entry, handleChange, submitForm, validEmail} = props

  const renderHints = entry.fields.renderHints ?? []
  if (renderHints.indexOf('Sign Up') === -1) {return null}

  let callToAction = entry.fields.displayCallToAction ?? false
  let url = false
  if (callToAction && entry.fields.callToActionArticle) {
    url = entry.fields.callToActionArticle.fields && entry.fields.callToActionArticle.fields.url ? entry.fields.callToActionArticle.fields.url : false
    if (url && entry.fields.callToActionArticle.fields.type === 'Blog Post') {
      url = 'blog/' + url
    }
    if (url && entry.fields.callToActionArticle.fields.type === 'Event') {
      url = 'events/' + url
    }
  }

  return (
    <>
    <div className='headline'>{entry.fields.tagline}</div>
    <ReactMarkdown source={entry.fields.body} escapeHtml={false}/>

    <Form.Control type="email" name="email" placeholder="Your email" style={{marginBottom: 10}} onChange={handleChange}/>
    <CallToAction
      internalLink={url}
      externalLink={entry.fields.callToActionUrl}

    >
      <Button variant='warning' disabled={!validEmail} style={{marginBottom: 10}} onClick={submitForm}>{entry.fields.callToAction}</Button>
    </CallToAction>
    </>
  )
}

function isValidEmail(email) { // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase()) && !email.includes('%');
}
