import * as contentful from 'contentful'
import React from 'react'

const port = window.location.port

const contentfulClient = contentful.createClient(
  { space: 'p3r8buzp5omt',
    accessToken: 'vlbLNvSYSBoiCvWWc-1g7ZoyPng92__zMJlJdgTfnZI',
    environment: (port === '3000') ? 'develop' : 'master'
   })

export const ContentfulContext = React.createContext({contentfulClient: contentfulClient})
export const HOME_PAGE_QUERY = {
  'content_type': 'article',
  'fields.type[in]': 'Home Page',
  'order': '-sys.createdAt',
  'limit': 1
}

export const GLOBAL_FOOTER_1_QUERY = {
  'content_type': 'section',
  'fields.type[in]': 'Global Footer Part 1',
  'order': '-sys.createdAt',
  'limit': 1
}

export const GLOBAL_FOOTER_2_QUERY = {
  'content_type': 'section',
  'fields.type[in]': 'Global Footer Part 2',
  'order': '-sys.createdAt',
  'limit': 1
}

export let ARTICLE_GET = {
  'content_type': 'article',
  'sys.id': '%1',
}

export function TAG_SEARCH(searchTerm) {
  return {
    'content_type': 'article',
    'fields.tag[match]': searchTerm
  }
}

export function CONTENT_SEARCH(searchTerm) {
  return {
    'content_type': 'article',
    'query': searchTerm
  }
}

export function LEGACY_URL(url) {
  return {
    'content_type': 'urlMapping',
    'fields.url': 'https://bordersforesttrust.org' + url,
  }
}
