import React from "react"
import { Link } from "react-router-dom"

import Container from "react-bootstrap/Container"

export default function Sitemap(props) {

  const {routes } = props
  const routesSorted = routes.filter(a => a.url && a.url !== '/404').sort((a, b) => {
    if (a.url < b.url) {return -1}
    if (a.url > b.url) {return 1}
    return 0
  })

  return (
    <Container id="sitemap" fluid >
      <ul >
        {routesSorted.map(route => {
          let indent = (route.url.match(/\//g) || []).length * 20 - 20
          if (route.url !== '/') indent += 20
          return (
            <li style={{marginLeft: indent}}><em className='sitemap-url'>{route.url}</em> <Link to={route.url}>{route.headline}</Link></li>
          )
        })}
      </ul>
    </Container>
  )
}
