import React from 'react'
import MultipleItemComponent from '../../classes/MultipleItemComponent'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import NavLink from 'react-bootstrap/NavLink'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import CardDeck from 'react-bootstrap/CardDeck'

class ThreeCards extends MultipleItemComponent {

  render() {

    const {item, entries} = this.state
    const {zIndex} = this.props

    if (!entries) return null

    let topEdgeTopStyle = {
      paddingTop: '1rem',
      backgroundColor: '#f3f1e6' // To Do. Make these styles classes.
    }

    switch (item.roughEdgeTop) {
      case 'Off White Hero':
        topEdgeTopStyle = {
          marginTop: -80,
          paddingTop: 40,
          backgroundImage: `url("/images/rough-edge-off-white.png")`,
          backgroundRepeat: "no-repeat",
          zIndex: zIndex
        }
        break
      case 'Lime Green Hero':
        topEdgeTopStyle = {
          marginTop: -80,
          paddingTop: 40,
          backgroundImage: `url("/images/rough-edge-lime-green.png")`,
          backgroundRepeat: "no-repeat",
          zIndex: zIndex
        }
        break
      case 'Off White':
        topEdgeTopStyle = {
          marginTop: -30,
          paddingTop: 35,
          backgroundImage: `url("/images/rough-edge-off-white.png")`,
          backgroundRepeat: "no-repeat",
          zIndex: zIndex
        }
        break
      case 'Lime Green':
        topEdgeTopStyle = {
          marginTop: -30,
          paddingTop: 35,
          backgroundImage: `url("/images/rough-edge-lime-green.png")`,
          backgroundRepeat: "no-repeat",
          zIndex: zIndex
        }
        break
      default:
    }

    return (
      <Container id='three-cards' fluid style={topEdgeTopStyle}>
        <Row>
          <Col style={{paddingLeft: 0, paddingRight: 0, marginBottom: '-2rem'}}>
          <CardDeck >
          {entries.slice(0, 3).map((item, i) =>
              <BftCard item={item} key={i}/>
            )
          }
          </CardDeck>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default ThreeCards;
// style={{height: '5rem', overflow: 'hidden', textOverflow: 'ellipsis'}}
function BftCard(props) {
  const {item} = props

  const cardImage = item.fields.image ?
                    item.fields.image.fields.file.url :
                    null

  let heading = <h2>{item.fields.headline}</h2>
  let image = <Card.Img variant="top" style={{height: 300, objectFit: 'cover'}} src={cardImage} />
  if (item.fields.callToAction) {
    if (item.fields.callToActionArticle) {
      heading = <h2><NavLink style={{padding: 0}} href={item.fields.callToActionArticle.fields.url}>{item.fields.headline}</NavLink></h2>
      image = <NavLink style={{padding: 0}} href={item.fields.callToActionArticle.fields.url}><Card.Img variant="top" style={{height: 300, objectFit: 'cover'}} src={cardImage} /></NavLink>
    } else if (item.fields.callToActionUrl) {
      heading = <h2><NavLink style={{padding: 0}} href={item.fields.callToActionUrl}>{item.fields.headline}</NavLink></h2>
      image = <NavLink style={{padding: 0}} href={item.fields.callToActionUrl}><Card.Img variant="top" style={{height: 300, objectFit: 'cover'}} src={cardImage} /></NavLink>
    }
  }
  return (
    <Col lg={4} md={12} sm={12} xs={12}>
      <Card className="h-100">
        <Card.Body>
          <Card.Title style={{height: '3rem', textAlign: 'center'}}>
            {heading}x
          </Card.Title>
          <Card.Text style={{textAlign: 'center'}} className="tagline truncate-overflow">{item.fields.tagline}</Card.Text>
          {image}
        </Card.Body>
      </Card>
    </Col>
  )
}
