import React, { Component } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import EventSummary from '../widgets/EventSummary'

import moment from 'moment'

class EventList extends Component {

  render() {

    const {routes, headlineImage} = this.props

    const postsSorted = routes
      .filter(route => route.articleType === 'event' && moment().isBefore(moment(route.publishedAt)))
      .sort((a, b) => {
        if (a.publishedAt > b.publishedAt) {return 1}
        if (a.publishedAt < b.publishedAt) {return -1}
        return 0
      })
    return (
      <Col>
        <div id='article-heading-block'>
          <Row>
            {postsSorted.map(route => {
              return (
                <EventSummary id={route.id} headlineImage={headlineImage}/>
              )
            })}
          </Row>
        </div>
      </Col>
    )
  }
}

export default EventList;
